<script>
import { MoreHorizontalIcon } from "@zhuowenli/vue-feather-icons";
// import Multiselect from "@vueform/multiselect";
// import "@vueform/multiselect/themes/default.css";

import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import FieldVisitConfigServices from "../../services/FieldVisitConfigServices";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Liste des visites",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Liste des visites",
      items: [
        {
          text: "Visites",
          href: "/",
        },
        {
          text: "Liste des Visites",
          active: true,
        },
      ],
      fieldVisits: [],
      currentVisit: null,
      fields: {
        field_id: 1,
        trip_mode: "car",
        amount: 0,
        sheduled_at: null,
      },
      isCreateModalOpen: false,
      isUpdateModalOpen: false,
    };
  },
  components: {
    Layout,
    PageHeader,
    // Multiselect,
    MoreHorizontalIcon,
  },
  async beforeRouteEnter() {
    let visits = [];
    await FieldVisitConfigServices.getAll()
      .then((data) => {
        visits = data.visit_configs;
      })
      .catch((er) => {
        console.log(er);
      });

    localStorage.setItem("@FIELD_VISITS_LIST", JSON.stringify(visits));
  },
  beforeMount() {
    this.fieldVisits = JSON.parse(
      localStorage.getItem("@FIELD_VISITS_LIST") || "[]"
    );
  },
  methods: {
    async getFieldVisits() {
      await FieldVisitConfigServices.getAll()
        .then((data) => {
          this.fieldVisits = data.visit_configs;
        })
        .catch((er) => {
          console.log(er);
        });
    },
    handleOpenCreate() {
      this.isCreateModalOpen = true;
    },
    handleOpenUpdate(visit) {
      this.isUpdateModalOpen = true;
      this.currentVisit = visit;
      this.fields.field_id = visit.field_id;
      this.fields.amount = visit.amount;
      this.fields.trip_mode = visit.trip_mode;
      this.fields.sheduled_at = visit.sheduled_at
        .split(".")[0]
        .replace("T", " ");
    },
    async handleDeleteFieldVisit(item) {
      await Swal.fire({
        title: "Êtes-vous sûr?",
        text: "Cette opération est irréversible !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Oui, supprimer!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await FieldVisitConfigServices.delete(item.id)
            .then(() => {
              this.fieldVisits = this.fieldVisits.filter(
                (visit) => visit.id !== item.id
              );
              Swal.fire(
                "Supprimé!",
                "La visite a bien été supprimée.",
                "success"
              );
            })
            .catch((er) => {
              console.log(er);
            });
        }
      });
    },
    async handleCreateFieldVisit() {
      FieldVisitConfigServices.create(this.fields).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite créée avec succès",
            icon: "success",
          });

          this.fields = {
            field_id: null,
            trip_mode: null,
            amount: null,
            sheduled_at: null,
          };
        }

        this.getFieldVisits();
      });
    },
    async handleUpdateFieldVisit() {
      FieldVisitConfigServices.update(this.currentVisit.id, {
        ...this.fields,
        sheduled_at: new Date(this.fields.sheduled_at).toISOString(),
      }).then((res) => {
        console.log(res);
        if (!res.error) {
          Swal.fire({
            title: "Confirmation",
            text: "Visite mise à jour avec succès",
            icon: "success",
          });
        }

        this.getFieldVisits();
      });
    },
  },
  mounted() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal
      v-model="isCreateModalOpen"
      hide-footer
      title="Ajout d'une visite"
      class="v-modal-custom"
      header-class="bg-light p-3"
    >
      <form action="#" id="addform">
        <div class="mb-3">
          <label for="status-field" class="form-label"> Choisir le site </label>
          <select
            class="form-control"
            data-trigger
            id="statusfield"
            v-model="fields.field_id"
          >
            <option value="2">PK 30 TONDE CITY</option>
            <option value="3">BOMONE</option>
            <option value="4">TERRAIN NGOMBE</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="email-field" class="form-label"> Date et heure </label>
          <input
            type="datetime-local"
            data-bs-provider="flatpickr"
            data-bs-date-format="d.m.y"
            data-bs-enable-time
            id="date"
            class="form-control"
            placeholder="Entrer l'heure et la date"
            required
            v-model="fields.sheduled_at"
          />
        </div>

        <div class="mb-3">
          <label for="status-field" class="form-label">
            Mode de transport
          </label>
          <select
            class="form-control"
            data-trigger
            id="statusfield"
            v-model="fields.trip_mode"
          >
            <option value="car">Voiture</option>
            <option value="bus">Bus</option>
            <option value="bycicle">Moto</option>
          </select>
        </div>

        <div class="mb-3">
          <label for="amount" class="form-label">Montant</label>
          <input
            type="tel"
            id="phone"
            class="form-control"
            placeholder="Entrer le coût de la visite"
            required
            v-model="fields.amount"
          />
        </div>
        <div class="modal-footer v-modal-footer">
          <div class="hstack gap-2 justify-content-end">
            <button
              type="button"
              id="closemodal"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              Fermer
            </button>
            <button
              type="button"
              class="btn btn-success"
              @click="handleCreateFieldVisit"
            >
              Enregistrer la visite
            </button>
          </div>
        </div>
      </form>
    </b-modal>

    <div class="row g-4 mb-3">
      <!-- <div class="col-sm-auto">
        <div>
          <button class="btn btn-success" @click="handleOpenCreate">
            <i class="ri-add-line align-bottom me-1"></i> Nouvelle visite
          </button>
        </div>
      </div> -->
      <div class="col-sm">
        <div class="d-flex justify-content-sm-end gap-2">
          <div class="search-box ms-2">
            <input
              type="text"
              class="form-control"
              placeholder="Rechercher..."
            />
            <i class="ri-search-line search-icon"></i>
          </div>

          <!-- <Multiselect
            class="multiselect form-control w-lg w-auto m-0"
            v-model="value"
            :close-on-select="true"
            :searchable="true"
            :create-option="true"
            :options="[
              { value: 'Status', label: 'Status' },
              { value: 'Active', label: 'Active' },
              { value: 'Block', label: 'Block' },
            ]"
          /> -->
        </div>
      </div>
    </div>

    <div class="row" style="gap: 12px">
      <div
        class="col-xxl-4 col-sm-6 project-card"
        style="border: 2px solid gray; border-radius: 12px"
        v-for="(item, index) of fieldVisits"
        :key="index"
      >
        <div class="card card-height-100">
          <div class="card-body">
            <div class="d-flex flex-column h-100">
              <div class="d-flex">
                <div class="flex-grow-1">
                  <p class="mb-4">Configuration N°{{ index + 1 }}</p>
                </div>
                <div class="flex-shrink-0">
                  <div class="d-flex gap-1 align-items-center">
                    <div class="dropdown">
                      <button
                        class="btn btn-link text-muted p-1 mt-n2 py-0 text-decoration-none fs-15 shadow-none"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="true"
                      >
                        <MoreHorizontalIcon
                          class="icon-sm"
                        ></MoreHorizontalIcon>
                      </button>

                      <div class="dropdown-menu dropdown-menu-end">
                        <router-link
                          class="dropdown-item"
                          :to="{
                            name: 'field-visit-configs.details',
                            params: { id: item.id },
                          }"
                          ><i
                            class="ri-eye-fill align-bottom me-2 text-muted"
                          ></i>
                          Voir les inscriptions
                        </router-link>
                        <div class="dropdown-divider"></div>
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          @click.prevent="handleDeleteFieldVisit(item)"
                        >
                          <i
                            class="ri-delete-bin-fill align-bottom me-2 text-muted"
                          ></i>
                          Supprimer
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <h6 class="mb-2">{{ item.field.name }}</h6>

              <div class="d-flex" style="gap: 12px">
                <div
                  v-for="(tripMode, index) in item.trip_modes"
                  :key="index"
                  class="mb-2"
                  style="
                    border: 2px solid gray;
                    border-radius: 12px;
                    padding: 5px 10px;
                  "
                >
                  <div class="fw-bold">{{ tripMode.mode_fr }}</div>
                  <div class="text-success">
                    {{ $formatPrice(tripMode.price) }}
                  </div>
                </div>
              </div>

              <h5 class="mt-2">Plages horaires</h5>
              <div class="d-flex" style="gap: 8px">
                <div
                  v-for="(timeSlot, index) in item.time_slots"
                  :key="index"
                  class="mb-2"
                  style="
                    border: 1px solid blue;
                    padding: 4px 8px;
                    border-radius: 8px;
                  "
                >
                  <div class="">{{ timeSlot[0] }} - {{ timeSlot[1] }}</div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex mb-2">
                  <div class="flex-grow-1">
                    <div>Souscriptions</div>
                  </div>
                  <div class="flex-shrink-0">
                    <div>
                      <i class="ri-list-check align-bottom me-1 text-muted"></i>
                      {{ String(item.prospects.length).padStart(2, "0") }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
          <div class="card-footer bg-transparent border-top-dashed py-2">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <div class="text-muted">
                  <i class="ri-calendar-event-fill me-1 align-bottom"></i>
                  Ajouté le {{ new Date(item.created_at).toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
          <!-- end card footer -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <!-- <div class="row g-0 text-center text-sm-start align-items-center mb-4">
      <div class="col-sm-6">
        <div>
          <p class="mb-sm-0 text-muted">
            Showing <span class="fw-semibold">1</span> to
            <span class="fw-semibold">10</span> of
            <span class="fw-semibold text-decoration-underline">12</span>
            entries
          </p>
        </div>
      </div>
      <div class="col-sm-6">
        <ul
          class="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0"
        >
          <li class="page-item disabled">
            <a href="#" class="page-link">Previous</a>
          </li>
          <li class="page-item active">
            <a href="#" class="page-link">1</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">2</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">3</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">4</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">5</a>
          </li>
          <li class="page-item">
            <a href="#" class="page-link">Next</a>
          </li>
        </ul>
      </div>
    </div> -->
    <!-- end row -->
  </Layout>
</template>
